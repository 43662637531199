<template>
  <div class="promotion">
    <section>
      <div class="mask" v-if="showModal">
        <div class="lottos-modal" @click="close()">
          <div class="win-ti">中奖啦！</div>
          <div class="win">恭喜获得<span style="color: #DD483F;"> {{win.name}}</span></div>
          <div class="win-pic">
            <img :src="win.icon" alt="">
          </div>
        </div>
      </div>
      <div class="title">
        <img src="https://img.xinshu.me/upload/4869f112ede74124bc738a9a17818828"
             width="90%" alt="">
      </div>
      <div class="person">
        <img class="bg" src="https://img.xinshu.me/upload/aa814c21cd3747108fcff61c3cca8db3"
             width="93%" alt="">
        <div class="ava">
          <img :src="user.avatar" alt="">
        </div>
        <div class="text-box">
          <div class="line1">尊敬的{{user.nickname}}</div>
          <div class="line2">
            您在心书一共购买了 <span class="big-text"> {{lottosData.orderCount}} </span> 单
          </div>
          <div class="line3 mt-2" v-if="!lottosData.orderCount">不能参与抽奖哦</div>
          <div class="line3" v-else>
            可抽奖 <span class="big-text">{{lottosData.totalLottosCount}}</span> 次,
            剩余 <span class="big-text">{{lottosData.remainLottosCount}}</span> 次
          </div>
        </div>
      </div>
      <div class="info">
        <img src="https://img.xinshu.me/upload/156f32f408144a7091870ed7491f3c09"
             width="50%" alt="">
      </div>
      <div class="spin-wrapper">
        <div class="outer-bg">
          <div class="spin-bg" :style="`transform: rotate(${wheelDeg}deg)`" :class="{rolling}">
            <div class="prize-list">
              <div
                class="prize-item-wrapper"
                v-for="(item, index) in prizeList"
                :key="index"
              >
                <div
                  class="prize-item"
                  :style="`transform: rotate(${(360/ prizeList.length) * index - 22.5}deg)`"
                >
                  <div class="prize-name">
                    {{item.name}}
                  </div>
                  <div class="prize-icon">
                    <img :src="item.icon">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="spin-pointer" @click="onClickRotate" v-if="lottosData.orderCount">
            <img src="https://img.xinshu.me/upload/841d71012e754dacb5b4cabe5121ba7c"
                 v-if="rolling"
                 width="24%" alt="">
            <img src="https://img.xinshu.me/upload/fe8d2ded9e544f369d81f20c1a6de742"
                 v-else
                 width="24%" alt="">
          </div>
        </div>
      </div>
      <div class="prize-btn" v-if="lottosData.orderCount > 0">
        <img src="https://img.xinshu.me/upload/56b4b505c9ca484eb07b6fd511239a39"
             width="60%" alt="" @click="goPrize">
      </div>
      <div class="rule-box">
        <div class="rule-ti">活动规则</div>
        <hr class="rule-hr">
        <div class="rule">1. 在心书下单过1次，可获得1次抽奖次数，每个用户最多可抽取10次</div>
        <div class="rule">2. 奖品以兑换码的形式发放，兑换码可以赠送给自己的好友使用</div>
        <div class="rule">3. 兑换码有效期为15天，请及时兑换使用，避免过期</div>
        <div class="rule">4. 产品兑换码仅可免费兑换产品，下单需额外支付邮费</div>
        <div class="rule">5. 本活动最终解释权归心书所有，如有其它问题，可在公众号联系客服进行咨询</div>
      </div>
    </section>
  </div>
</template>

<script>
import { wait } from '@/utils/wait-for'

export default {
  name: 'anniversary7th',
  title: '心书7周年幸运大转盘',
  data() {
    return {
      lottosData: {
        orderCount: 0,
        totalLottosCount: 0,
        remainLottosCount: 0
      },
      lottosResult: '',
      win: {},
      showModal: false,
      rolling: false,
      wheelDeg: 0,
      prizeNumber: 8,
      prizeListOrigin: [
        {
          code: 'b5album',
          icon: 'https://img.xinshu.me/upload/bdeca7afb6914371a6f59b25f18ad0b1',
          name: '锁线精装照片书'
        },
        {
          code: 'xalbum',
          icon: 'https://img.xinshu.me/upload/ad06f373e8f545a4968512acacfe7004',
          name: 'A4精装纪念册'
        },
        {
          code: 'postcard',
          icon: 'https://img.xinshu.me/upload/ecb8d5df6b184c8ca43e3cfccf4df40a',
          name: 'LOMO卡'
        },
        {
          code: 'xcalbum',
          icon: 'https://img.xinshu.me/upload/0f5ee965a655491f863ca9e88a3df3d2',
          name: 'A4轻奢杂志册'
        },
        {
          code: 'frame-moment',
          icon: 'https://img.xinshu.me/upload/000f6e601546465e8ca6a6b501e2a373',
          name: '朋友圈相框'
        },
        {
          code: 'calendar-2021',
          icon: 'https://img.xinshu.me/upload/0a2b8d83ca4646768d9c6793900b9bae',
          name: '2021经典台历'
        },
        {
          code: 'note',
          icon: 'https://img.xinshu.me/upload/1b97f57e1181425aaa5b4667df39b192',
          name: '亲子笔记本'
        },
        {
          code: 'nogift',
          icon: 'https://img.xinshu.me/upload/d3ba25bfd1fb4e999ae811a12f406e71',
          name: '谢谢惠顾'
        }
      ]
    }
  },
  computed: {
    prizeList() {
      return this.prizeListOrigin.slice(0, this.prizeNumber)
    }
  },
  beforeDestroy() {
    this.close()
  },
  mounted() {
    this.init()
  },
  methods: {
    goPrize() {
      this.$router.push('/promotions/7th-prize')
    },
    close() {
      this.showModal = false
      document.body.classList.remove('modal-open')
    },
    show() {
      this.showModal = true
      document.body.classList.add('modal-open')
    },
    async init() {
      this.lottosResult = {}
      this.win = {}
      this.showModal = false
      this.rolling = false
      await this.getLottos()
    },
    async getLottos() {
      try {
        const result = await this.$req.get('/api/seventh_anniversary/lottos')
        result.orderCount = Number(result.orderCount || 0)
        result.totalLottosCount = Number(result.totalLottosCount || 0)
        result.remainLottosCount = Number(result.remainLottosCount || 0)
        this.lottosData = result
      } catch (error) {
        console.log(error)
      }
    },
    async startLottos() {
      try {
        this.lottosResult = ''
        const result = await this.$req.post('/api/seventh_anniversary/lottos')
        this.lottosResult = result.tid
      } catch (error) {
        this.rolling = false
        this.$alert.error('网络错误，请重新试试')
      }
    },
    async onClickRotate() {
      if (this.lottosData.remainLottosCount === 0) {
        this.$alert.warn('抽奖次数已经用完啦')
        return
      }

      if (this.rolling) {
        return
      }

      this.rolling = true
      await this.startLottos()

      if (!this.lottosResult) {
        this.$alert.error('啊偶，抽奖出了些问题哦，请重新试试吧')
        return
      }

      const {wheelDeg, prizeList} = this
      const index = prizeList.findIndex(i => this.lottosResult === i.code)

      this.win = prizeList[index]
      this.wheelDeg += -wheelDeg % 360 + 6 * 360 + (360 - 360 / prizeList.length * index) + 22.5

      await wait(4500)

      this.rolling = false
      this.show()
      this.getLottos()
    }
  }
}
</script>

<style lang="scss">
body[data-page="7thAnniversary"] {
  main {
    padding-bottom: 0;
  }
}
</style>

<style scoped lang="scss">
$darkColor: #332535;

.promotion {
  max-width: 480px;
  padding: 0;
  user-select: none;
  overflow-x: hidden;

  section {
    background-image: url('https://img.xinshu.me/upload/b04f2f8838c04a83aadf0385b8af6826');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 100vh;

    .mask {
      max-width: 480px;
      position: fixed;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .6);
      z-index: 30;
      overflow: hidden;
      visibility: visible;

      .lottos-modal {
        background-image: url('https://img.xinshu.me/upload/e6f0424c5e65420bb1b796e206988db2');
        background-repeat: no-repeat;
        position: relative;
        width: 80%;
        padding-bottom: 80%;
        height: 0;
        background-size: 100% 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: $darkColor;

        .win-ti {
          padding-top: 9%;
          font-size: 1.8rem;
          font-weight: bold;
        }

        .win {
          padding-top: 3.5%;
          font-size: 1.2rem;
        }

        .win-pic {
          position: absolute;
          padding-top: 3.5%;
          height: 43%;
          margin: auto;
          left: 0;
          right: 0;

          img {
            height: 100%;
          }
        }
      }
    }

    .title:after {
      content: '';
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }

    .title img {
      margin-top: 1.2rem;
      margin-right: 1rem;
      float: right;
    }

    .person:after {
      content: '';
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }

    .person {
      position: relative;

      .ava {
        position: absolute;
        width: 15%;
        border: $darkColor 2px solid;
        bottom: 15.4%;
        left: 10%;
      }

      .text-box {
        color: $darkColor;
        position: absolute;
        letter-spacing: 1px;
        top: 28%;
        left: 40%;

        .big-text {
          color: #DD483F;
          font-size: 140%;
          font-weight: bold;
        }
      }

      .bg {
        margin-top: 1.2rem;
        float: right;
      }
    }

    .info img {
      margin-top: 1.2rem;
      margin-left: 25%;
    }

    .spin-wrapper {
      width: 100%;
      padding-bottom: 100%;
      height: 0;

      .outer-bg {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        height: 0;
        background-image: url('https://img.xinshu.me/upload/addbeed85dd041dcb933a83840bcab16');
        background-size: 100% 100%;

        .spin-bg {
          position: absolute;
          left: 9%;
          top: 9%;
          width: 82%;
          padding-bottom: 82%;
          height: 0;
          background-image: url('https://img.xinshu.me/upload/5d92275cf1e94099839c64c8edf3d10b');
          background-size: 100% 100%;

          &.rolling {
            transition: transform 4s ease-in-out;
          }

          .prize-list {
            width: 100%;
            height: 100%;
            position: relative;
            text-align: center;
          }

          .prize-item-wrapper {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 50%;
            padding-bottom: 50%;
            height: 0;
          }

          .prize-item {
            position: absolute;
            width: 100%;
            height: 100%;
            transform-origin: bottom;

            .prize-name {
              color: $darkColor;
              font-weight: bold;
              padding: 1rem 0 0.4rem 0;
            }

            .prize-icon {
              img {
                width: 26%;
              }
            }
          }
        }

        .spin-pointer img {
          position: absolute;
          top: 37%;
          left: 38%;
          cursor: pointer;
        }
      }
    }

    .prize-btn img {
      margin-top: 1rem;
      margin-left: 20%;
      cursor: pointer;
    }

    .rule-box {
      color: $darkColor;
      padding: 2rem 1.5rem;

      .rule-ti {
        font-size: 16px;
      }

      .rule {
        font-size: 14px;
      }

      .rule:not(:last-child) {
        margin-bottom: 4px;
      }

      .rule-hr {
        border-top: 1px solid $darkColor;
      }
    }
  }

}
</style>
